import React from 'react';
import { Avatar, Card, Col, Row } from 'antd';
const { Meta } = Card;

export const CardComponents = (information) => {
  console.log(information);
  const socialAccounts = information.map((item, index) => (
    <Col key={index} xs={24} sm={24} md={24} lg={12} xl={12}>
      <Card
        style={{ width: '100%', marginBottom: '16px', cursor: item.accountUrl ? 'pointer' : 'default'}}
        bodyStyle={{ wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'pre-wrap' }}
        onClick={() => item.accountUrl && window.open(item.accountUrl, '_blank')}
      >
        <Meta
          avatar={<Avatar
            src={item.imgUrl ? item.imgUrl : "https://api.dicebear.com/7.x/miniavs/svg?seed=8"}
          />}
          title={
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {item.title}
              <div style={{ padding: '1%', fontSize: '14px', color: 'blue' }}>{item.subTitle}</div>
            </div>
          }
          description={item.description}
        />
      </Card>
    </Col>
  ));

  return (
    <Row gutter={[16, 16]}>
      {socialAccounts}
    </Row>
  );
};
