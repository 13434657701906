import React from 'react';
import { Card, Col, Row, Typography } from 'antd';
import './css/ProfileInfo.css';

const cardStyle = {
  width: '100%',
  height: 'auto'
};

const imgStyle = {
  width: '100%',
  height: 'auto',
};

const contentStyle = {
  padding: '10px',
  textAlign: 'center',
};

const contentTitleStyle = {
  fontWeight: 'bold',
};

export const ProfileInfo = () => (
  <Card className='profile-card' hoverable style={cardStyle} bodyStyle={{ padding: 0, overflow: 'hidden' }}>
    <Row justify="center">
      <Col span={16}>
        <img
          alt="avatar"
          src="/images/burcu.jpeg"
          style={imgStyle}
        />
      </Col>
    </Row>
    <Row justify="center">
      <Col span={16}>
        <div style={contentStyle}>
          <Typography.Title level={3} style={contentTitleStyle}>
            Burcu Arslan
          </Typography.Title>
          <Typography.Paragraph>
            Computer Engineer | Java & SAP CX Developer
          </Typography.Paragraph>
        </div>
      </Col>
    </Row>
  </Card>
);
