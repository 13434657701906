import { CardComponents } from './CardComponents';

const json = [
    {
        "title": "ENOCA, APRIL 2023 - PRESENT - JAVA SAP CX DEVELOPER",
        "subTitle":"CARREFOURSA HYBRIS B2C E-COMMERCE PROJECT – TURKEY",
        "description": "• Web service development for mobile application \n• Project based ERP, CRM web service development \n• Custom product's order process development \n• Pages, Order etc. bug fixes \n• Backend development using Java, Spring and XML configurations, \n• Backoffice development \n• Solr indexing, search and facet development, \n• Impex import and export operations, groovy script development, \n• Establishing SOAP, RESTful and file transfer integrations between SAP Commerce and any other data provider \n• 3rd party application integration \n• Checkout development.",
        "imgUrl":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyiHNj3neZsIKfJ0pzWxeP2Ytyxt-k28UQPQ&usqp=CAU"
    }
    ,
    {
        "title": "KODLINE",
        "description":"- REST API development using Laravel\n - 3rd party application integration" ,
        "imgUrl":"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSyiHNj3neZsIKfJ0pzWxeP2Ytyxt-k28UQPQ&usqp=CAU"
       
    }
];


export const Experiences = () => {
    return (CardComponents(json))
};
