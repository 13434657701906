import React from 'react';
import { Layout, Typography, Row, Col } from 'antd';
import { Link } from 'react-router-dom'; // Örneğin react-router-dom kullanıyorsanız, uygun olanını seçin

const { Header } = Layout;
const { Title } = Typography;

const headerStyle = {
  background: '#fff',
  textAlign: 'center',
  paddingBottom: '4%'
};

const titles = [
  { level: 4, text: 'Home', link: '/' },
  { level: 6, text: 'Hi!', style: { color: 'blue', fontSize: '24px', fontWeight: 'bold' }},
  { level: 4, text: 'Blog', link: '/blog' },
];

const CustomHeader = () => (
  <Layout>
    <Header style={headerStyle}>
      <Row gutter={[16, 16]}>
        {titles.map((title, index) => (
          <Col key={index} xs={8} sm={8} md={8} lg={8} xl={8}>
            <Link to={title.link}>
              <Title level={title.level}>{title.text}</Title>
            </Link>
          </Col>
        ))}
      </Row>
    </Header>
  </Layout>
);

export default CustomHeader;
