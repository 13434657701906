import './App.css';
import HomePage from './pages/HomePage.jsx';

function App() {
  return (
   <HomePage></HomePage>
  );
}

export default App;
