import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'; // React Router'ı import et

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router> {/* BrowserRouter'ı kullanın */}
    <App />
  </Router>,
  document.getElementById('root')
);

reportWebVitals();

