import React from 'react';
import { Layout, Row, Col, Divider } from 'antd';
import { ProfileInfo } from '../components/ProfileInfo.jsx';
import CustomHeader from '../components/CustomHeader.jsx';
import { SocialAccounts } from '../components/SocialAccounts.jsx';
import '../css/HomePage.css';
import { Educations } from '../components/Educations.jsx';
import { Experiences } from '../components/Experiences.jsx';


const { Content } = Layout;

const contentStyle = {
    padding: '24px',
    minHeight: '280px',
};

const HomePage = () => {
    return (
        <Layout>
            <CustomHeader />
            <Content style={contentStyle}>
                <Row gutter={[16, 16]} className="custom-row" style={{ margin: 0 }}>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                        <div className="profile-container">
                            <ProfileInfo />
                        </div>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={2} xl={2} />
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} offset={{ lg: 2, xl: 2 }}>
                        <div className="social-accounts-container">
                            <Divider style={{ fontWeight: 'bold', fontSize: '1.5rem', color: 'darkblue' }} orientation="left">
                                Experiences
                            </Divider>
                            <Experiences />
                            <Divider style={{ fontWeight: 'bold', fontSize: '1.5rem', color: 'darkblue' }} orientation="left">
                                Social Accounts
                            </Divider>
                            <SocialAccounts />
                            <Divider style={{ fontWeight: 'bold', fontSize: '1.5rem', color: 'darkblue'  }} orientation="left">
                                Educations
                            </Divider>
                            <Educations />
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}

export default HomePage;
